export const epms = [
  {
    path: "design/index",
    name: "DesignIndex",
    component: () => import("@/views/epms/design/index.vue"),
  },
  {
    path: "design/filltaskbill",
    name: "DesignFillTaskBill",
    component: () => import("@/views/epms/design/filltaskbill.vue"),
  },
  {
    path: "design/taskdetail/:id",
    name: "DesignTaskDetail",
    component: () => import("@/views/epms/design/taskdetail.vue"),
  },
  {
    path: "design/taskpay/:id",
    name: "DesignTaskPay",
    component: () => import("@/views/epms/design/taskpay.vue"),
  },
  {
    path: "design/signcontract/:id",
    name: "DesignSignContract",
    component: () => import("@/views/epms/design/signcontract.vue"),
  },
  {
    path: "design/taskprepare/:id",
    name: "DesignTaskPrepare",
    component: () => import("@/views/epms/design/taskprepare.vue"),
  },
  {
    path: "design/schemedesign/:id",
    name: "DesignSchemeDesign",
    component: () => import("@/views/epms/design/schemedesign.vue"),
  },
  {
    path: "design/basedesign/:id",
    name: "DesignBaseDesign",
    component: () => import("@/views/epms/design/basedesign.vue"),
  },
  {
    path: "design/drawdesign/:id",
    name: "DesignDrawDesign",
    component: () => import("@/views/epms/design/drawdesign.vue"),
  },
  {
    path: "design/techdisclosure/:id",
    name: "DesignTechDisclosure",
    component: () => import("@/views/epms/design/techdisclosure.vue"),
  },
  {
    path: "design/aftercare/:id",
    name: "DesignAfterCare",
    component: () => import("@/views/epms/design/aftercare.vue"),
  },
  {
    path: "consult/index",
    name: "ConsultIndex",
    component: () => import("@/views/epms/consult/index.vue"),
  },
  {
    path: "consult/filltaskbill",
    name: "ConsultFillTaskBill",
    component: () => import("@/views/epms/consult/filltaskbill.vue"),
  },
  {
    path: "consult/taskdetail/:id",
    name: "ConsultTaskDetail",
    component: () => import("@/views/epms/consult/taskdetail.vue"),
  },
  {
    path: "consult/taskpay/:id",
    name: "ConsultTaskPay",
    component: () => import("@/views/epms/consult/taskpay.vue"),
  },
  {
    path: "consult/signcontract/:id",
    name: "ConsultSignContract",
    component: () => import("@/views/epms/consult/signcontract.vue"),
  },
  {
    path: "consult/taskprepare/:id",
    name: "ConsultTaskPrepare",
    component: () => import("@/views/epms/consult/taskprepare.vue"),
  },
  {
    path: "consult/resultdeliver/:id",
    name: "ConsultDrawDesign",
    component: () => import("@/views/epms/consult/resultdeliver.vue"),
  },
  {
    path: "consult/techdisclosure/:id",
    name: "ConsultTechDisclosure",
    component: () => import("@/views/epms/consult/techdisclosure.vue"),
  },
  {
    path: "plan/index",
    name: "PlanIndex",
    component: () => import("@/views/epms/plan/index.vue"),
  },
  {
    path: "plan/filltaskbill",
    name: "PlanFillTaskBill",
    component: () => import("@/views/epms/plan/filltaskbill.vue"),
  },
  {
    path: "plan/taskdetail/:id",
    name: "PlanTaskDetail",
    component: () => import("@/views/epms/plan/taskdetail.vue"),
  },
  {
    path: "plan/taskpay/:id",
    name: "PlanTaskPay",
    component: () => import("@/views/epms/plan/taskpay.vue"),
  },
  {
    path: "plan/signcontract/:id",
    name: "PlanSignContract",
    component: () => import("@/views/epms/plan/signcontract.vue"),
  },
  {
    path: "plan/taskprepare/:id",
    name: "PlanTaskPrepare",
    component: () => import("@/views/epms/plan/taskprepare.vue"),
  },
  {
    path: "plan/resultdeliver/:id",
    name: "PlanDrawDesign",
    component: () => import("@/views/epms/plan/resultdeliver.vue"),
  },
  {
    path: "plan/techdisclosure/:id",
    name: "PlanTechDisclosure",
    component: () => import("@/views/epms/plan/techdisclosure.vue"),
  },
  {
    path: "engineer/index",
    name: "EngineerIndex",
    component: () => import("@/views/epms/engineer/index.vue"),
  },
  {
    path: "engineer/filltaskbill",
    name: "EngineerFillTaskBill",
    component: () => import("@/views/epms/engineer/filltaskbill.vue"),
  },
  {
    path: "engineer/taskdetail/:id",
    name: "EngineerTaskDetail",
    component: () => import("@/views/epms/engineer/taskdetail.vue"),
  },
  {
    path: "engineer/signcontract/:id",
    name: "EngineerSignContract",
    component: () => import("@/views/epms/engineer/signcontract.vue"),
  },
];
