export const account = [
  {
    path: "/account",
    name: "Account",
    component: () => import("@/layout/account.vue"),
    children: [
      {
        path: "uplogin",
        name: "UpLogin",
        component: () => import("@/views/account/uplogin.vue"),
      },
      {
        path: "smslogin",
        name: "SmsLogin",
        component: () => import("@/views/account/smslogin.vue"),
      },
      {
        path: "wxlogin",
        name: "WxLogin",
        component: () => import("@/views/account/wxlogin.vue"),
      },
      {
        path: "resetpwd",
        name: "ResetPwd",
        component: () => import("@/views/account/resetpwd.vue"),
      },
      {
        path: "bindmember",
        name: "BindMember",
        component: () => import("@/views/account/bindmember.vue"),
      },
      {
        path: "reg",
        name: "Reg",
        component: () => import("@/views/account/reg.vue"),
      },
    ],
  },
];
