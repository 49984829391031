export const mcenter = [
  {
    path: "/mcenter",
    name: "MCenter",
    component: () => import("@/layout/mcenter.vue"),
    children: [
      {
        path: "index",
        name: "MCenterIndex",
        component: () => import("@/views/mcenter/index.vue"),
      },
      {
        path: "myaddress",
        name: "MyAddress",
        component: () => import("@/views/mcenter/myaddress.vue"),
      },
      {
        path: "myinvoice",
        name: "MyInvoice",
        component: () => import("@/views/mcenter/myinvoice.vue"),
      },
      {
        path: "useragreement",
        name: "UserAgreement",
        component: () => import("@/views/mcenter/useragreement.vue"),
      },
      {
        path: "privacypolicy",
        name: "PrivacyPolicy",
        component: () => import("@/views/mcenter/privacypolicy.vue"),
      },
      {
        path: "updatepwd",
        name: "UpdatePwd",
        component: () => import("@/views/mcenter/updatepwd.vue"),
      },
      {
        path: "savequit",
        name: "SaveQuit",
        component: () => import("@/views/mcenter/savequit.vue"),
      },
    ],
  },
];
