import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { account } from "./account";
import { mcenter } from "./mcenter";
import { mementer } from "./mementer";
import { epay } from "./epay";
import { buss } from "./buss";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "homeIndex",
    component: () => import("@/views/index/index.vue"),
  },
  {
    path: "/test",
    name: "testIndex",
    component: () => import("@/views/test/index.vue"),
  },
  ...account,
  ...mcenter,
  ...mementer,
  ...epay,
  ...buss,
  {
    path: "/:pathMatch(.*)*",
    name: "NoFound",
    component: () => import("@/views/error/404.vue"),
  },
];
console.info(routes);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
