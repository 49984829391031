export const epay = [
  {
    path: "/epay",
    name: "EPay",
    component: () => import("@/views/epay/layout.vue"),
    children: [
      {
        path: "cashier/:payOrderId",
        name: "PayCashier",
        component: () => import("@/views/epay/cashier.vue"),
      },
      {
        path: "wxpay/:payOrderId",
        name: "WxPay",
        component: () => import("@/views/epay/wxpay.vue"),
      },
      {
        path: "alipay/:payOrderId",
        name: "AliPay",
        component: () => import("@/views/epay/alipay.vue"),
      },
      {
        path: "payok/:payOrderId",
        name: "PayOk",
        component: () => import("@/views/epay/payok.vue"),
      },
    ],
  },
];
