export const mementer = [
  {
    path: "/mementer",
    name: "MemEnter",
    component: () => import("@/layout/mementer.vue"),
    children: [
      {
        path: "designerAgreement",
        name: "DesignerAgreement",
        component: () => import("@/views/mementer/designer_agreement.vue"),
      },
      {
        path: "designerInfo",
        name: "DesignerInfo",
        component: () => import("@/views/mementer/designer_info.vue"),
      },
      {
        path: "designerResult",
        name: "DesignerResult",
        component: () => import("@/views/mementer/designer_result.vue"),
      },
      {
        path: "studioAgreement",
        name: "StudioAgreement",
        component: () => import("@/views/mementer/studio_agreement.vue"),
      },
      {
        path: "studioInfo",
        name: "StudioInfo",
        component: () => import("@/views/mementer/studio_info.vue"),
      },
      {
        path: "studioResult",
        name: "StudioResult",
        component: () => import("@/views/mementer/studio_result.vue"),
      },
      {
        path: "teacherAgreement",
        name: "TeacherAgreement",
        component: () => import("@/views/mementer/teacher_agreement.vue"),
      },
      {
        path: "teacherInfo",
        name: "TeacherInfo",
        component: () => import("@/views/mementer/teacher_info.vue"),
      },
      {
        path: "teacherResult",
        name: "TeacherResult",
        component: () => import("@/views/mementer/teacher_result.vue"),
      },
      {
        path: "enterpriseAgreement",
        name: "EnterpriseAgreement",
        component: () => import("@/views/mementer/enterprise_agreement.vue"),
      },
      {
        path: "enterpriseInfo",
        name: "EnterpriseInfo",
        component: () => import("@/views/mementer/enterprise_info.vue"),
      },
      {
        path: "enterpriseResult",
        name: "EnterpriseResult",
        component: () => import("@/views/mementer/enterprise_result.vue"),
      },
      {
        path: "workerAgreement",
        name: "WorkerAgreement",
        component: () => import("@/views/mementer/worker_agreement.vue"),
      },
      {
        path: "workerInfo",
        name: "WorkerInfo",
        component: () => import("@/views/mementer/worker_info.vue"),
      },
      {
        path: "workerResult",
        name: "WorkerResult",
        component: () => import("@/views/mementer/worker_result.vue"),
      },
      {
        path: "clouddeskAgreement",
        name: "ClouddeskAgreement",
        component: () => import("@/views/mementer/clouddesk_agreement.vue"),
      },
      {
        path: "clouddeskInfo",
        name: "ClouddeskInfo",
        component: () => import("@/views/mementer/clouddesk_info.vue"),
      },
      {
        path: "clouddeskResult",
        name: "ClouddeskResult",
        component: () => import("@/views/mementer/clouddesk_result.vue"),
      },
    ],
  },
];
