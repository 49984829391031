export const ecoll = [
  {
    path: "ecoll/index",
    name: "CollIndex",
    component: () => import("@/views/ecoll/index.vue"),
  },
  {
    path: "ecoll/list",
    name: "ECollList",
    component: () => import("@/views/ecoll/list.vue"),
  },
  {
    path: "ecoll/detail/:id",
    name: "ECollDetail",
    component: () => import("@/views/ecoll/detail.vue"),
  },
  {
    path: "ecoll/confirmorder/:id",
    name: "ECollConfirmOrder",
    component: () => import("@/views/ecoll/confirmorder.vue"),
  },
  {
    path: "ecoll/myorder",
    name: "MyECollOrder",
    component: () => import("@/views/ecoll/myorder.vue"),
  },
  {
    path: "ecoll/mycollection",
    name: "MyECollCollection",
    component: () => import("@/views/ecoll/mycollection.vue"),
  },
  {
    path: "ecoll/myfootprint",
    name: "MyECollFootprint",
    component: () => import("@/views/ecoll/myfootprint.vue"),
  },
];
