import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default defineComponent({
  components: {
    ElConfigProvider
  },

  setup() {
    return {
      zIndex: 3000,
      size: "default",
      locale: zhCn
    };
  }

});