export const egjy = [
  {
    path: "studio/list",
    name: "StudioList",
    component: () => import("@/views/gjy/studio/list.vue"),
  },
  {
    path: "studio/detail/:id",
    name: "StudioDetail",
    component: () => import("@/views/gjy/studio/detail.vue"),
  },

  {
    path: "project/index",
    name: "ProjectIndex",
    component: () => import("@/views/gjy/project/index.vue"),
  },
  {
    path: "project/detail/:id",
    name: "ProjectDetail",
    component: () => import("@/views/gjy/project/detail.vue"),
  },
  {
    path: "project/bid/:id",
    name: "ProjectBid",
    component: () => import("@/views/gjy/project/bid.vue"),
  },
  {
    path: "hr/index",
    name: "HrIndex",
    component: () => import("@/views/gjy/hr/index.vue"),
  },
  {
    path: "allservice/index",
    name: "AllServiceIndex",
    component: () => import("@/views/gjy/allservice/index.vue"),
  },
  {
    path: "assemble/index",
    name: "AssembleIndex",
    component: () => import("@/views/gjy/assemble/index.vue"),
  },
  {
    path: "3dprint/index",
    name: "3dprintIndex",
    component: () => import("@/views/gjy/3dprint/index.vue"),
  },
  {
    path: "3dprint/printreq",
    name: "3dprintReq",
    component: () => import("@/views/gjy/3dprint/printreq.vue"),
  },
  {
    path: "cloudplat/netdisk",
    name: "cloudplatnetdisk",
    component: () => import("@/views/gjy/cloudplat/netdisk.vue"),
  },
  {
    path: "cloudplat/intro",
    name: "cloudplatintro",
    component: () => import("@/views/gjy/cloudplat/intro.vue"),
  },
  {
    path: "cloudplat/doc",
    name: "cloudplatdoc",
    component: () => import("@/views/gjy/cloudplat/doc.vue"),
  },
  {
    path: "cloudplat/myclouddeskAccountInfo",
    name: "myclouddeskAccountInfo",
    component: () => import("@/views/gjy/cloudplat/myclouddeskAccountInfo.vue"),
  },
  {
    path: "cloudplat/dataoutsend",
    name: "dataoutsend",
    component: () => import("@/views/gjy/dataoutsend/index.vue"),
  },
  {
    path: "culturallegacy/index",
    name: "CulturalLegacyIndex",
    component: () => import("@/views/gjy/culturallegacy/index.vue"),
  },
];
