export const eshop = [
  {
    path: "eshop/index",
    name: "EShopIndex",
    component: () => import("@/views/eshop/index.vue"),
  },
  {
    path: "eshop/list",
    name: "EShopList",
    component: () => import("@/views/eshop/list.vue"),
  },
  {
    path: "eshop/detail/:id",
    name: "EShopDetail",
    component: () => import("@/views/eshop/detail.vue"),
  },
  {
    path: "eshop/shopcar",
    name: "EShopCar",
    component: () => import("@/views/eshop/shopcar.vue"),
  },
  {
    path: "eshop/confirmorder/:id",
    name: "EShopConfirmOrder",
    component: () => import("@/views/eshop/confirmorder.vue"),
  },
  {
    path: "eshop/myorder",
    name: "MyEShopOrder",
    component: () => import("@/views/eshop/myorder.vue"),
  },
  {
    path: "eshop/mycollection",
    name: "MyEShopCollection",
    component: () => import("@/views/eshop/mycollection.vue"),
  },
  {
    path: "eshop/myfootprint",
    name: "MyEShopFootprint",
    component: () => import("@/views/eshop/myfootprint.vue"),
  },
];
