import { ecms } from "./ecms";
import { ecoll } from "./ecoll";
import { epms } from "./epms";
import { eshop } from "./eshop";
import { egjy } from "./egjy";
export const buss = [
  {
    path: "/buss",
    name: "Bussiness",
    component: () => import("@/layout/buss.vue"),
    children: [...ecms, ...ecoll, ...epms, ...eshop, ...egjy],
  },
];
