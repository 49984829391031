export const ecms = [
  {
    path: "notice/list",
    name: "NoticeList",
    component: () => import("@/views/ecms/notice/list.vue"),
  },
  {
    path: "notice/detail/:id",
    name: "NoticeDetail",
    component: () => import("@/views/ecms/notice/detail.vue"),
  },
  {
    path: "case/list",
    name: "CaseList",
    component: () => import("@/views/ecms/case/list.vue"),
  },
  {
    path: "case/detail/:id",
    name: "CaseDetail",
    component: () => import("@/views/ecms/case/detail.vue"),
  },
  {
    path: "news/list",
    name: "NewsList",
    component: () => import("@/views/ecms/news/list.vue"),
  },
  {
    path: "news/detail/:id",
    name: "NewsDetail",
    component: () => import("@/views/ecms/news/detail.vue"),
  },
  {
    path: "faq/list",
    name: "FaqList",
    component: () => import("@/views/ecms/faq/list.vue"),
  },
  {
    path: "faq/detail/:id",
    name: "FaqDetail",
    component: () => import("@/views/ecms/faq/detail.vue"),
  },
];
